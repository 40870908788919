const INTER = [
  { space_src: "baidujs", space_type: "inters", space_id: "u6917464", space_js: "//dspb.chiimiifunny.com/production/pkj/source/s/tlg/openjs/jg.js" }
];
const HOMEDIALOG = [
  { space_src: "baidujs", space_type: "native", space_id: "u6917465", space_js: "//dspb.chiimiifunny.com/production/q/lk_tu_m/production/hki.js" }
];
const TOPNATIVE = [
  { space_src: "baidujs", space_type: "native", space_id: "u6917467", space_js: "//dspb.chiimiifunny.com/production/s/common/nmv_wo/openjs/jm/resource/o.js" }
];
const CENTER = [
  { space_src: "baidujs", space_type: "native", space_id: "u6917468", space_js: "//dspb.chiimiifunny.com/common/t-o/static/n/production/wx-pknq.js" }
];
const BANNER = [{ space_src: "baidujs", space_type: "native", space_id: "u6917466", space_js: "//dspb.chiimiifunny.com/common/r/mlu_v/source/n_ill.js" }];
const DETAILS = [
  { space_src: "baidujs", space_type: "native", space_id: "u6917468", space_js: "//dspb.chiimiifunny.com/common/t-o/static/n/production/wx-pknq.js" }
];
/** 广告位配置 */
export const SPACE_MAP = {
  Home_inter: INTER, // 官方插屏
  Home_dialog: HOMEDIALOG, // 自定义插屏
  Home_top: TOPNATIVE, // 首页top信息流
  Home_center: CENTER, // 首页contenr信息流
  Home_banner: BANNER, // Banner
  Details_center: DETAILS // 商品详情信息流
};
