<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts"></script>

<template>
  <div class="icp">
    ICP备案/许可证号：粤ICP备2022099218号-1
    <p>侈迷欧罗巴-深圳市侈迷电子商务有限公司</p>
  </div>
</template>

<style lang="less" scoped>
.icp {
  width: 100%;
  font-size: 26px;
  text-align: center;
  margin-top: 20px;
  padding-bottom: 40px;
}
</style>
